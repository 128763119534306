.interior-designer-management-table .table-responsive {
  overflow-x: unset !important;
}

.interior-designer-management-table .dropdown-menu {
  max-width: 180px !important;
  min-width: auto !important;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: visible;
  min-height: 50vh;
}

.table td,
.table th {
  white-space: nowrap;
}
