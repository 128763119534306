@font-face {
  font-family: "Neue Montreal"; /* The name you want to use for the font family */
  src: url("./NeueMontreal-Bold.otf") format("opentype");
  src: url("./NeueMontreal-Light.otf") format("opentype");
  src: url("./NeueMontreal-Regular.otf") format("opentype");
  /* You can add additional font formats (e.g., woff, woff2) for better browser support */
}
@font-face {
  font-family: "Neue Montreal Medium";
  src: url("./NeueMontreal-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Rackless Neue";
  src: url("./RecklessNeue-Book.woff") format("opentype");
}
a {
  text-decoration: none !important;
}
.nav-item a {
  color: #7e7e7e;
}
.notification {
  padding: 50px;
}
.css-yk16xz-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border: 1px solid #f0f1f5 !important;
  border-radius: 12px !important;
}
.css-1pahdxg-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-color: #2684ff !important;
  border-radius: 12px !important;
  box-shadow: 0 0 0 0 #2684ff !important;
}
/* ._loading_overlay_content {
padding-right: 350px;
padding-bottom: 350px;
} */
.user-select {
  border: 1px solid #f0f1f5 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-radius: 12px !important;
}
.hidden-button {
  width: 30% !important;
}
select:required:invalid {
  color: gray;
}
.rs-loader-backdrop {
  background: rgb(255 255 255 / 70%) !important;
}
.css-tlfecz-indicatorContainer {
  padding-right: 0px !important;
}
.card {
  font-family: "Neue Montreal Medium";
  background: var(--bg-100-opacity, #f1f0ee);
}
.card-header {
  border-color: #333333;
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  display: block;
  align-items: center;
}
.bg-theme {
  /* background-color: #a4a3a3; */
  background-color: #d0cece;

  /* background: var(--light-bg, rgba(241, 240, 238, 0.60)); */
}
thead {
  font-family: "Reckless Neue";
}
tbody {
  font-family: "Neue Montreal Medium";
}
.page-titles {
  font-family: "Rackless Neue";
}
.deznav .metismenu {
  font-family: "Rackless Neue";
  font-weight: 900;
}
.footer .copyright {
  font-family: "Neue Montreal";
}
.form-head {
  font-family: "Rackless Neue";
  font-weight: 900;
}
.table thead th {
  border-color: #333333;
}
.table tbody tr td {
  border-color: #333333;
}
.bio-border {
  border-left: 1px solid #333333;
}
.btn-active {
  color: #fff;
  background-color: #3e4954;
  border-color: #3e4954;
}
.login-form-bx {
  font-family: "Rackless Neue";
  font-weight: 900;
}
.auth-form {
  font-family: "Rackless Neue";
  font-weight: 900;
}
.details-font {
  font-family: "Rackless Neue";
}
.btn-outline-secondary {
  border-radius: 57px;
  border-color: #333333;
}
.radio-input {
  width: 18px;
  height: 18px;
}

.border-input {
  border: none;
  border-bottom: 1px solid #333333;
}
.project-detail-card p {
  font-size: 16px;
  color: #333333;
}
.project-detail-card label {
  font-size: 16px;
  color: #333333;
}
.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  background-color: #333333;
}
.noUi-connect {
  background-color: #333333;
}
.upload-container {
  width: 185px;
  border: 0.8px dashed var(--3333, #333);
  background: var(--light-bg, rgba(241, 240, 238, 0.6));
}

.btn-color{
  background-color: #333333;
  border-color: #333333;
  color: #fff;
}
.gap-1{
  gap: 1rem;
}
/* input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
    background-color: red;
    color: white;
    outline: none;
} */
/* input[type="date"]::-webkit-calendar-picker-indicator {
  color: green; 
} */
