.customFileWrap {
  margin-bottom: 25px;
}
.uploadFilesField {
  border-radius: 5px;
  border: 1px dashed #333;
  background: rgba(246, 244, 238, 0.5);
  display: flex;
  position: relative;
  align-items: center;
  justify-content: start;
  width: 180px;
  height: 180px;
  cursor: pointer;
}
.uploadFilesField p {
  color: #000;
}
.textareaContent {
  background-color: transparent;
  resize: none;
  padding: 16px 20px;
  margin-top: 10px;
  font-size: 14px !important;
  font-family: var(--font-family-montreal) !important;
  color: var(--theme-main-color);
}
.textareaContent::placeholder {
  font-family: var(--font-family-montreal) !important;
  color: var(--theme-main-color);
  font-size: 14px !important;
}
