.accountBox {
  border-radius: 10px;
  background: var(--light-bg, rgba(241, 240, 238, 0.6));
  padding: 20px 28px 10px 28px;
}

.flexBetween {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.basicDetailFormInputField {
  text-align: left;
  overflow-x: hidden;
}

.basicDetailFormInputField > fieldset {
  width: 100%;
  margin: 0 0 24px 0;
  text-align: left;
}

.basicDetailFormInputField > fieldset label {
  margin: 0;
  transform: unset;
}

.basicDetailFormInputField textarea {
  padding: 20px;
  border-radius: 10px;
}

.basicDetailFormInputField legend {
  color: var(--black);
}

.basicDetailFormInputField label {
  position: relative;
  margin-bottom: 20px;
  transform: translate(0px, 16px) scale(1);
  color: var(--black);
  text-transform: capitalize;
}

.basicDetailFormInputField .MuiFormControl-root {
  margin-bottom: 20px;
}

.basicDetailFormInputField.clientDetails label,
.basicDetailFormInputField.projectInquery label {
  margin-bottom: 0px;
}

.basicDetailFormInputField .inqueryContent {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

.basicDetailFormInputField .inqueryContent .MuiInputBase-root {
  border-radius: 100px;
}

.basicDetailFormInputField .inqueryContent fieldset {
  margin-bottom: 0px !important;
}

.basicDetailFormInputField .inqueryContent label {
  margin-bottom: 30px !important;
}

.basicDetailFormInputField .inqueryContent input {
  height: 12px;
  border-radius: 100px !important;
}

@media (max-width: 767px) {
  .basicDetailFormInputField .inqueryContent {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .basicDetailFormInputField .inqueryContent {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .basicDetailFormInputField .inqueryContent {
    grid-template-columns: repeat(3, 1fr);
  }
}

.basicDetailFormInputField.clientDetails > div {
  margin-bottom: 30px;
}

.basicDetailFormInputField.clientDetails fieldset {
  margin-bottom: 20px;
}

.basicDetailFormInputField.projectInquery fieldset legend {
  color: var(--black) !important;
  line-height: 2.4375em;
}

.basicDetailFormInputField.projectInquery p {
  margin-top: 10px;
}

.basicDetailFormInputField.projectInquery label {
  transform: translate(0px, 0px) scale(1);
}

.as-seen-in {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
