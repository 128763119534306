.authPopupWrap button {
  margin-top: 0px;
}
.authPopupWrap .authPopupContent {
  text-align: center;
}

.authPopupWrap .authPopupContent .padImage {
  display: inline-block;
  text-align: center;
  margin-bottom: 20px;
}

.authPopupWrap .authPopupContent .padImage img {
  display: inline-block;
}
